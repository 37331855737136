import { InputGroup } from "react-bootstrap";
import React from "react";
import "./styles.scss";

const UIFormInput = ({
  measure,
  value,
  onChange,
  label,
  className,
  placeholder,
  classNameTitle,
  type = "number",
  id,
  name,
  min,
  max,
}) => {
  return (
    <div className={`d-flex flex-column p-1 ${className}`}>
      <span className={`pb-1 ${classNameTitle} `}>{label}</span>
      <InputGroup className="align-items-center">
        <input
          className={`form-control input`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          id={id}
          name={name}
          min={min}
          max={max}
        />
        {measure && <span className="measure-input">{measure}</span>}
      </InputGroup>
    </div>
  );
};

export default React.memo(UIFormInput);
