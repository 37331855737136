import React, { useState } from "react";
import Select from "react-select";
import "./styles.scss";

const SelectSearchMulti = (props) => {
  const {
    onChange,
    default: defaultOptions = [],
    className = "",
    isClearable = true,
    backspaceRemovesValue = true,
    isDisabled = false,
    itemLabel,
    itemValue,
    placeholder,
    id,
    value,
    options,
    title,
    classNameTitle,
    values,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
  const [newValue, setNewValue] = useState("");

  const getSelectedOptions = () => {
    if (value === 0) return [];
    return getOptions().filter((option) =>
      selectedOptions.includes(option.value)
    );
  };

  const getOptions = () => {
    const stringOptions = options.map((item) => ({
      label: item[itemLabel],
      value: item[itemValue],
    }));
    return stringOptions;
  };

  const handleOnChange = (selectedValues) => {
    setSelectedOptions(selectedValues.map((option) => option.value));
    onChange(selectedValues.map((option) => option.value));
  };

  const handleChangeInput = (inputValue) => {
    setNewValue(inputValue);
  };

  const handleClick = async (e) => {
    if (e.key === "Enter" && newValue !== "") {
      if (selectedOptions.find((item) => item === newValue)) {
        setNewValue("");
        return;
      }
      const newOption = { label: newValue, value: newValue };
      setSelectedOptions([...selectedOptions, newOption.value]);
      setNewValue("");
      onChange([...selectedOptions, newOption.value]);
    }
  };

  return (
    <div className={`d-flex flex-column p-1 ${className}`}>
      <span className={`pb-1 ${classNameTitle}`}>{title}</span>
      <Select
        className={"select" + className}
        value={
          values?.length !== 0
            ? values?.map((item) => ({ label: item, value: item }))
            : []
        }
        onChange={handleOnChange}
        onKeyDown={handleClick}
        onInputChange={handleChangeInput}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        options={getOptions()}
        placeholder={placeholder}
        id={id}
        isDisabled={isDisabled}
        inputValue={newValue}
        isMulti
      />
    </div>
  );
};

export default SelectSearchMulti;
