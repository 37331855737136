import { Card } from "react-bootstrap";
import "./styles.scss";
import { useEffect, useState } from "react";
import GeneratorWhitePage from "../../components/GeneratorWhitePage";
import { getDalleModels, getGptModel, getLocales, getThemes } from "./action";
import { useDispatch, useSelector } from "react-redux";
import ListWhitePage from "../../components/ListWhitePage";
import { vaultService } from "../../core/services/vault-service";

const WhitePage = () => {
  const service = vaultService();
  const themes = useSelector((state) => state.whitePageReducer.themes);
  const dalle = useSelector((state) => state.whitePageReducer.dalle);
  const locales = useSelector((state) => state.whitePageReducer.locales);
  const gptModels = useSelector((state) => state.whitePageReducer.gptModels);

  const tab = service.getItem("tab");

  const [activeTab, setActiveTab] = useState(tab ?? 1);

  const dispatch = useDispatch();

  const loadData = () => {
    if (gptModels.length === 0) {
      dispatch(getGptModel());
    }
    if (dalle.length === 0) {
      dispatch(getDalleModels());
    }
    if (themes.length === 0) {
      dispatch(getThemes());
    }
    if (locales.length === 0) {
      dispatch(getLocales());
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
    service.setItem("tab", tab);
  };
  return (
    <Card className="wrapper">
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
            <h5>White Page</h5>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="d-flex gap-5 border-bottom pt-3 pb-3 wrapper-tabs">
          <span
            className={activeTab === 1 && "activeTab"}
            onClick={() => changeActiveTab(1)}
          >
            Генератор White Page
          </span>
          <span
            className={activeTab === 2 && "activeTab"}
            onClick={() => changeActiveTab(2)}
          >
            List White Page
          </span>
        </div>
        <div className="d-flex flex-column">
          {activeTab === 1 ? <GeneratorWhitePage /> : <ListWhitePage />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default WhitePage;
