import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./styles.scss";

const SelectSearch = (props) => {
  const {
    onChange,
    default: defaultOption = "",
    className = "",
    isClearable = true,
    backspaceRemovesValue = true,
    isDisabled = false,
    itemLabel,
    itemValue,
    placeholder,
    id,
    value,
    options,
    title,
    classNameTitle,
  } = props;

  const [selected, setSelected] = useState(defaultOption);

  useEffect(() => {
    if (defaultOption !== undefined && selected !== defaultOption) {
      setSelected(defaultOption);
    }
  }, [defaultOption, selected]);

  const getSelectedOptions = () => {
    if (value === 0) return defaultOption;
    return getOptions().find((option) => option.value + "" === selected + "");
  };

  const getOptions = () => {
    const stringOptions = (options || []).map((item) => ({
      label: item[itemLabel],
      value: item[itemValue],
    }));
    return stringOptions;
  };

  const handleOnChange = (option) => {
    if (option === null) {
      option = { value: defaultOption };
    }
    setSelected(option.value + "");
    onChange(option.value);
  };

  return (
    <div className={`d-flex flex-column p-1 ${className}`}>
      <span className={`pb-1 ${classNameTitle} `}>{title}</span>
      <Select
        className={`select ${className}`}
        value={getSelectedOptions() ? getSelectedOptions() : defaultOption}
        onChange={handleOnChange}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        options={getOptions()}
        placeholder={placeholder}
        id={id}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectSearch;
