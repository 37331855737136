/* eslint-disable */

import {
  GET_DALLE_MODALS,
  GET_LOCALES,
  GET_THEMES,
  GET_GPT_MODEL,
  GET_WHITE_PAGES,
} from "./types";

const initialState = {
  themes: [],
  dalle: [],
  locales: [],
  gptModels: [],
  whitePages: {
    data: [],
    meta: {
      per_page: 15,
      last_page: 1,
      total: 1,
      current_page: 1,
    },
  },
};

const whitePageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_THEMES:
      return {
        ...state,
        themes: payload.map((item) => ({ label: item, value: item })),
      };
    case GET_DALLE_MODALS:
      return {
        ...state,
        dalle: payload.map((item) => ({ label: item, value: item })),
      };
    case GET_LOCALES:
      return {
        ...state,
        locales: payload.map((item) => ({ label: item, value: item })),
      };

    case GET_GPT_MODEL:
      return {
        ...state,
        gptModels: payload.map((item) => ({ label: item, value: item })),
      };

    case GET_WHITE_PAGES:
      return {
        ...state,
        whitePages: payload,
      };

    default:
      return state;
  }
};

export default whitePageReducer;
