import { useState } from "react";
import { Button } from "react-bootstrap";
import docCookies from "../../core/services/cookie";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/helpers/routes";
import { NotificationContainer } from "react-notifications";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);

  const navigate = useNavigate();

  const handleChangeEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const submitClickHandler = async () => {
    // const resp = await dispatch(loginByEmail(email, password));
    if (email !== "admin" || password !== "1234567890") {
      setErrorLogin(true);
      setTimeout(() => {
        setErrorLogin(false);
      }, 3000);
      return;
    }

    if (email === "admin" && password === "1234567890") {
      docCookies.setItem("token", "1234567890");
      navigate(ROUTES.page.link);
      window.location.reload();
    }
  };
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="ml-auto mr-auto">
        <div className="border-bottom pb-4">
          <h1 className="text-silver">С возвращением.</h1>
          <span className="text-bold">
            Пожалуйста, войдите в свою учетную запись.
          </span>
        </div>

        <div className="d-flex flex-column gap-4 mt-4 align-items-center justify-content-center border-bottom pb-5 position-relative">
          <div className="d-flex gap-4 mt-4 align-items-center justify-content-center">
            <input
              className="form-control"
              type="text"
              placeholder="Почта"
              value={email}
              style={{ width: "367px", height: "50px" }}
              onChange={handleChangeEmail}
            />
            <input
              className="form-control"
              type="password"
              placeholder="Пароль"
              style={{ width: "367px", height: "50px" }}
              value={password}
              onChange={handleChangePassword}
            />
          </div>

          {errorLogin && (
            <div
              className="d-flex text-danger position-absolute"
              style={{ bottom: 0 }}
            >
              <span>Не правильный логин или пароль</span>
            </div>
          )}
        </div>
        <div className="mt-4 d-flex gap-4 align-items-center justify-content-end">
          <div className="d-flex align-items-center gap-4">
            <Button
              style={{ height: "40px", width: "150px" }}
              className="ml-auto"
              variant="primary"
              onClick={submitClickHandler}
            >
              Войти
            </Button>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;
