/* eslint-disable  */
import axios from "axios";
import { ROUTES } from "../helpers/routes";
import docCookies from "../services/cookie";

const baseURL = process.env.REACT_APP_API_URL;

const axiosConfig = {
  baseURL: `${baseURL}/`,
};

const instance = axios.create(axiosConfig);

instance.interceptors.request.use(
  async (req) => {
    const accessToken = docCookies.getItem("token");
    if (accessToken && req.headers) {
      req.headers.Authorization = `Bearer ${accessToken}`;
      req.headers["Content-Type"] = "application/json";
    }
    return req;
  },
  (err) => {
    throw err;
  }
);

instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  (err) => {
    const pathname = window.location.pathname;

    if (err.response.status === 401) {
      if (pathname === ROUTES.authorization.link) {
        docCookies.removeItem("token");
      } else {
        window.location.href = `${window.location.origin}${ROUTES.authorization.link}`;
        docCookies.removeItem("token");
      }
    }
    throw err;
  }
);

const API = instance;

export { API };
