import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { useEffect, useState } from "react";
import { ReactComponent as Show } from "../../assets/images/show.svg";
import { ReactComponent as Reload } from "../../assets/images/reload.svg";
import { ReactComponent as Download } from "../../assets/images/download.svg";
import { getWhitePages } from "../../pages/WhitePage/action";
import ProgressBar from "../UI/ProgressBar";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/helpers/routes";
import ModalRegenerateWhitePage from "../Modals/RegenerateWhitePage";
import { Spinner } from "react-bootstrap";

const ListWhitePage = () => {
  const whitePages = useSelector((state) => state.whitePageReducer.whitePages);
  const [show, setShow] = useState(false);
  const [isDownloadId, setIsDownLoadId] = useState([]);
  const [edit, setEdit] = useState(null);
  const [page, setPage] = useState(1);
  let timer = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadData = async () => {
    const data = await dispatch(getWhitePages(page));
    if (data) {
      const load = data.find(
        (item) =>
          typeof item.percent_generation === "string" &&
          item.percent_generation !== "100%"
      );

      if (load) {
        const INTERVAL = 15000;
        timer = setTimeout(async () => {
          await dispatch(getWhitePages(page));
          if (load) {
            runUpdateCheckbox();
          }
        }, INTERVAL);
      }
    }
  };

  const openModal = (item) => {
    setShow(true);
    setEdit(item);
  };

  const closeModal = () => {
    setShow(false);
    setEdit(null);
  };

  const download = async (id, name) => {
    const downloadUrl = `http://95.216.4.23:8443/api/white-pages/${id}/download`;
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${name}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const closeModalAndRegenerate = async () => {
    setShow(false);
    setEdit(null);
    const data = await dispatch(getWhitePages(page));
    if (data) {
      const load = data.find(
        (item) =>
          typeof item.percent_generation === "string" &&
          item.percent_generation !== "100%"
      );

      if (load) {
        const INTERVAL = 15000;
        timer = setTimeout(async () => {
          await dispatch(getWhitePages(page));
          if (load) {
            runUpdateCheckbox();
          }
        }, INTERVAL);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const changePage = async (page) => {
    const currentPage = page.selected + 1;
    await setPage(currentPage);
    dispatch(getWhitePages(currentPage));
  };

  const runUpdateCheckbox = () => {
    const INTERVAL = 15000;
    timer = setTimeout(async () => {
      const data = await dispatch(getWhitePages(page));

      if (data) {
        const load = data?.find(
          (item) =>
            typeof item.percent_generation === "string" &&
            item.percent_generation !== "100%"
        );
        if (load) {
          runUpdateCheckbox();
          return;
        }
        clearTimeout(timer);
      }
    }, INTERVAL);
  };

  const handleClickShow = (id) => {
    navigate(ROUTES.showWhitePage.link.replace(":id", id));
  };

  return (
    <div className="List">
      <div className="List-header">
        <span>Archive name</span>
        <span>Domain name</span>
        <span>Locale</span>
        <span>Site Type</span>
        <span>Theme</span>
        <span>Generating</span>
      </div>
      <div className="List-body">
        {whitePages?.data?.map((item) => (
          <div key={item.id} className="List-item">
            <span>{item.name}</span>
            <span>{item.domain_name}</span>
            <span>{item.locale}</span>
            <span>{item.site_type}</span>
            <span>{item.theme}</span>
            <span>
              {item.percent_generation !== null ? (
                item.percent_generation !== "100%" ? (
                  <ProgressBar progress={item.percent_generation} />
                ) : (
                  <div className="List-complete">
                    <span>Complete</span>
                    <Show
                      className="show-icon"
                      onClick={() => handleClickShow(item.id)}
                    />
                    <Download
                      className="show-icon"
                      onClick={() => download(item.id, item.name)}
                    />
                    <Reload
                      onClick={() => openModal(item)}
                      className="show-icon"
                    />
                  </div>
                )
              ) : (
                <div className="List-error">
                  <span>Error</span>
                  <Reload
                    onClick={() => openModal(item)}
                    className="show-icon"
                  />
                </div>
              )}
            </span>
          </div>
        ))}
      </div>
      <div className="pagination">
        {whitePages.meta?.total > whitePages.meta?.per_page && (
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={whitePages.meta?.total / whitePages.meta?.per_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={changePage}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={page - 1}
          />
        )}
      </div>
      {show && (
        <ModalRegenerateWhitePage
          show={show}
          loadData={loadData}
          closeModal={closeModal}
          edit={edit}
          closeModalAndRegenerate={closeModalAndRegenerate}
        />
      )}
    </div>
  );
};

export default ListWhitePage;
