export const optionsType = [
  { label: "success", value: "success" },
  { label: "ty", value: "ty" },
];

export const optionsRegion = [
  { label: "europe", value: "europe" },
  { label: "usa", value: "usa" },
];

export const optionsScriptVersion = [{ label: "v0.0.1", value: "v0.0.1" }];

export const optionsScriptType = [
  { label: "type1", value: "type1" },
  { label: "type2", value: "type2" },
  { label: "type3", value: "type3" },
];

export const optionsTypeFilter = [
  { label: "local", value: "local" },
  { label: "redirect", value: "redirect" },
  { label: "return_http_code", value: "return_http_code" },
];

export const optionsLocalization = [
  { label: "RU", value: "RU" },
  { label: "UA", value: "UA" },
  { label: "EN", value: "EN" },
  { label: "PT", value: "PT" },
  { label: "ES", value: "ES" },
];

export const optionsTypePage = [
  { label: "one-page", value: "one-page" },
  { label: "multi-page", value: "multi-page" },
];

export const optionsFieldMode = [
  { label: "php", value: "php" },
  { label: "html", value: "html" },
];

export const optionsLocale = [
  { label: "en_EN", value: "en_EN" },
  { label: "ru_RU", value: "ru_RU" },
  { label: "uk_UA", value: "uk_UA" },
];
