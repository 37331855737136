import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";

import { thunk } from "redux-thunk";
import whitePageReducer from "../pages/WhitePage/reducer";

// reducers

const rootReducer = combineReducers({
  whitePageReducer: whitePageReducer,
});

const composeEnhancers = compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
