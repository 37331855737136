import { NotificationManager } from "react-notifications";
import { API } from "../../core/api";
import {
  GET_DALLE_MODALS,
  GET_THEMES,
  GET_LOCALES,
  GET_GPT_MODEL,
  GET_WHITE_PAGES,
} from "./types";
import {helperError} from "../../helpers/helperError";

export const getThemes = () => async (dispatch) => {
  try {
    const response = await API.get("directories/themes");
    dispatch({ type: GET_THEMES, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePages = (page) => async (dispatch) => {
  try {
    const response = await API.get(`white-pages?page=${page}`);
    dispatch({ type: GET_WHITE_PAGES, payload: response.data });
    return response.data.data;
  } catch (e) {
    helperError(e);
  }
};

export const getRegenerateText = (options, whitePage) => async () => {
  try {
    await API.put(
      `white-pages/${whitePage}/regenerate-json-structure`,
      options
    );
  } catch (e) {
    helperError(e);
  }
};

export const getRegenerateImg = (options, whitePage) => async () => {
  try {
    await API.put(
      `white-pages/${whitePage}/regenerate-images-structure`,
      options
    );
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePage = (id) => async () => {
  try {
    const response = await API.get(`white-pages/${id}`);
    return response.data;
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePagePreview = (id) => async () => {
  try {
    const response = await API.get(`white-pages/${id}/preview`);
    return response.data;
  } catch (e) {
    // NotificationManager.error(`${e.message}`, "", 3500);
    console.log(e)
  }
};

export const createWhitePage = (options) => async () => {
  try {
    const response = await API.post("white-pages", options);
    NotificationManager.success(`Генерация началась`, "", 3500);
    return response.data;
  } catch (e) {
    helperError(e);
  }
};

export const generatePrompt = (options) => async () => {
  try {
    const response = await API.post(`/white-pages/generate-prompt`, options);
    NotificationManager.success(`Промт успешно сгенерирован`, "", 3500);
    return response;
  } catch (e) {
    helperError(e);
  }
};

export const downloadWhitePage = (whitePage) => async () => {
  try {
    const response = await API.get(`white-pages/${whitePage}/download`);
    return response;
  } catch (e) {
    helperError(e);
  }
};

export const regenerateWhitePage = (whitePage, options) => async () => {
  try {
    await API.put(`white-pages/${whitePage}/regenerate`, options);
    NotificationManager.success(`Генерация началась`, "", 3500);
  } catch (e) {
    helperError(e);
  }
};

export const getLocales = () => async (dispatch) => {
  try {
    const response = await API.get("directories/locales");
    dispatch({ type: GET_LOCALES, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getGptModel = () => async (dispatch) => {
  try {
    const response = await API.get("directories/chat-gpt-models");
    dispatch({ type: GET_GPT_MODEL, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getDalleModels = () => async (dispatch) => {
  try {
    const response = await API.get("directories/dalle-models");
    dispatch({ type: GET_DALLE_MODALS, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};



export const getHeaderColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `directories/header-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};

export const getFooterColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `directories/footer-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};

export const getBtnColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `directories/btn-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};


export const getOtherColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `directories/other-elements-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};
