import { Button, Card } from "react-bootstrap";
import "./styles.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getRegenerateImg,
  getRegenerateText,
  getWhitePage,
  getWhitePagePreview,
} from "../WhitePage/action";
import { ROUTES } from "../../core/helpers/routes";

const ShowWhitePage = () => {
  let time = null;
  const [isLoading, setIsLoading] = useState(false);
  const [whitePage, setWhitePage] = useState(null);
  const [pages, setPages] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  const loadData = async () => {
    setIsLoading(true);
    const data = await dispatch(getWhitePage(id));
    const page = await dispatch(getWhitePagePreview(id));
    if (page) {
      setPages(true);
    } else {
      navigate(ROUTES.page.link);
      setPages(false);
    }

    if (data) {
      setWhitePage(data);
    }
    setIsLoading(false);
  };

  const intervalPages = () => {
    time = setTimeout(async () => {
      const page = await dispatch(getWhitePagePreview(id));
      if (page) {
        setPages(true);
        intervalPages();
      } else {
        navigate(ROUTES.page.link);
        clearTimeout(time);
        setPages(false);
      }
    }, 10000);
  };

  useEffect(() => {
    loadData();
    intervalPages();

    return () => {
      clearTimeout(time);
    };
  }, []);

  const clickShowFull = () => {
    window.open(
      `http://95.216.4.23:8443/api/white-pages/${id}/preview`,
      "_blank"
    );
  };

  if (isLoading || !whitePage) return;

  return (
    <Card className="Show">
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
            <h5>{whitePage.name}</h5>
            <div className="d-flex gap-2 align-items-center">
              <Button onClick={clickShowFull} variant="primary">
                Show full
              </Button>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <div style={{height: '100vh'}}>
          {pages && (
            <iframe
              src={`http://95.216.4.23:8443/api/white-pages/${id}/preview-with-editor`}
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ShowWhitePage;
