/* eslint-disable consistent-return */
export const vaultService = (type = 'storage') => {
	const storage = type === 'storage' ? localStorage : sessionStorage;

	return {
		setItem: (key, value) => {
			try {
				return storage.setItem(key, JSON.stringify(value));
			} catch (error) {
				return error;
			}
		},

		getItem: key => {
			try {
				const value = storage.getItem(key);
				return value && JSON.parse(value);
			} catch (error) {
				return error;
			}
		},

		clearAll: () => {
			storage.clear();
		},

		updateItem: (key, value) => {
			try {
				const prevValueStorageData = storage.getItem(key);
				const prevValue = prevValueStorageData && JSON.parse(prevValueStorageData);
				const newValue = { ...prevValue, value };
				return storage.setItem(key, JSON.stringify(newValue));
			} catch (error) {
				return error;
			}
		},

		removeItems: keys => {
			storage.removeItem(keys);
		},
	};
};
