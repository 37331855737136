import { useEffect } from "react";
import Header from "./header";
import Menu from "./menu";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import docCookies from "../../core/services/cookie";
import { ROUTES } from "../../core/helpers/routes";

const Template = ({ children }) => {
  const token = docCookies.getItem("token");

  const navigate = useNavigate();

  const checkToken = () => {
    if (token !== "1234567890") {
      navigate(ROUTES.authorization.path);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);
  return (
    <div className="wrapper_template">
      <Header />
      <div className="wrapper_content">
        {/* <Menu /> */}
        {children}
      </div>
    </div>
  );
};

export default Template;
