import { Modal } from "react-bootstrap";
import "./styles.scss";
import GeneratorWhitePage from "../../GeneratorWhitePage";

const ModalRegenerateWhitePage = ({
  closeModal,
  show,
  edit,
  closeModalAndRegenerate,
}) => {

  return (
    <Modal show={show} onHide={closeModal} className="modal-wrapper-regenerate">
      <Modal.Header closeButton id="test-modal-header">
        <Modal.Title>Regenerate White Page</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{overflow: "auto"}}>
        <GeneratorWhitePage edit={edit} closeModal={closeModalAndRegenerate} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalRegenerateWhitePage;
