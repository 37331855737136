import React, { useState } from "react";
import { Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BsFillCaretDownFill } from "react-icons/bs";
import docCookies from "../../core/services/cookie";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/helpers/routes";

// const LogoutTooltip = () => <Tooltip id="logout-tooltip">Logout</Tooltip>;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomDropdown = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  const toggleTooltip = async () => {
    await docCookies.removeItem("token");
    navigate(ROUTES.authorization.path);
    window.location.reload();
    setShowTooltip(!showTooltip);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <BsFillCaretDownFill />
      </Dropdown.Toggle>

      <OverlayTrigger show={showTooltip} placement="bottom" overlay={<></>}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={toggleTooltip}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </OverlayTrigger>
    </Dropdown>
  );
};

export default CustomDropdown;
