// import { vaultService } from "../../core/services/vault-service";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import CustomDropdown from "./Tooltip";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { ROUTES } from "../../core/helpers/routes";

const Header = () => {
  // const service = vaultService();
  // const user = service.getItem("user");

  return (
    <div className="wrapper_header">
      <Link to={ROUTES.page.link}>
        <div className="header_title">
          <img src={logo} alt="" />
        </div>
      </Link>
      <NotificationContainer />
      <div className="user">
        <span>White Page Generator</span>
        <CustomDropdown />
      </div>
    </div>
  );
};

export default Header;
